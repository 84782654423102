import React from 'react'
import '../css/services.css'

import bgs from '../img/servbg.png'
import header from '../img/headth.png'


function Services() {
    return (
        <div className='scontain'>
            <img id='header' src={header} alt='header' />
            <div className='one'>
                <div className='sboxOne'>
                    <p id='stit'>Scope of Professional Services</p>
                    <p>Turner's Cleaning Service can provide the following commercial janitorial service listed in thorough detail.</p>
                </div>
                <div> <img id='bg' src={bgs} alt='photoref' /></div>

            </div>
            <div className='servlist'>
                <div>
                    <p id='subtit'>
                        Trash Removal
                    </p>
                    <p id='conttext'>
                        All waste receptacles and other trash containers within the areas will be emptied as scheduled and returned to
                        their initial locations. Boxes, cans, papers, etc. placed near a trash receptacle will also be removed. All waste
                        from such trash receptacles will be removed from the areas and emptied into a designated trash dumpster or
                        receptacle in such a manner as to prevent the adjacent area from becoming littered by such trash. Dumpster
                        lids will be kept closed. Exterior of wastebaskets will be damp-wiped to remove evident soil and the inside
                        cleaned weekly. In restrooms, locker rooms, germicidal detergent will be used to damp-wipe trash containers
                        daily. In trash receptacles using plastic liners, the liners will be replaced when obviously soiled or torn.
                    </p>

                    <p id='subtit'>Dust-mop or Sweep of Non-Carpeted Areas</p>
                    <p id='conttext'>
                        Prior to sweeping or dust mopping, all surface litter such as paper, gum, rubber bands, paper clips, or spills will
                        be picked up. The entire area will be thoroughly swept to remove dust, dry soil, and other surface debris. All
                        areas such as under chairs, trash receptacles, desks, coin-operated machines, and other furnishings, which
                        are accessible and behind doors, will be swept. After sweeping, surfaces, including corners, abutments, and
                        places accessible to the mop will be free of all visible soil, streaks, litter and spots caused by spills.</p>

                    <p id='subtit'>Damp-Mop Spills of Non-Carpeted Floors</p>
                    <p id='conttext'>When stains and soil are not removed by the dry-cleaning methods, they are to be removed by spot damp
                        mopping. After spot mopping, the floor surfaces will present a uniform, clean appearance, free of spills, spots,
                        film, and other stains, which can be removed by the application of detergent from a well-wrung damp mop.</p>

                    <p id='subtit'> Wet-Mop Entrances</p>
                    <p id='conttext'>Entrance mats will be removed and cleaned. The surfaces will be swept, or dust mopped prior to wet mopping
                        to remove all loose soil and dust. All accessible areas will be mopped to remove all soil and nonpermanent
                        stains. After mopping, the floor will have a uniform appearance with no streaks, film, swirl marks, detergent
                        residue, mop strings or other evidence of soil. There will be no splash marks left on baseboards. Any splash
                        marks will be removed immediately. The entrance carpet will be replaced when the floor is completely dry.</p>

                    <p id='subtit'>Dust Building Surfaces</p>
                    <p id='conttext'>Remove dust, lint, dry soil, and cobwebs from baseboards, radiator registers, moldings, ledges, door and
                        window casings, blinds, window sills and benches, chalk board trays, handrails, walls, air and heat vents, wind
                        deflectors, and open metal gratings. After dusting, these surfaces will have a uniform appearance free from
                        streaks, smudges, lint, and cobwebs; and soil will be completely removed from the area. In restrooms and
                        showers, a cloth or sponge dampened with germicidal detergent will be used for dusting.</p>

                    <p id='subtit'>Dust Furniture</p>
                    <p id='conttext'>Remove all dust, lint, litter, dry soil, etc., from the surface of tables, chairs, cabinets, bookcases, shelves, and
                        other types of furniture and equipment; fabric surfaces of upholstered furniture will be vacuumed. Items on top
                        of furniture will not be disturbed or moved. After dusting, all such surfaces will have a uniform appearance, free
                        from streaks, smudges, dust, lint, litter, and cobwebs, with complete removal of soil from the surface.
                        Workstations and desks will be dusted upon request.</p>

                    <p id='subtit'>Restore Furniture to Normal Arrangement</p>
                    <p id='conttext'>In the process of normal daily room use, furniture is often displaced from its standard arrangement. Also, in
                        several areas, furniture must be moved to perform various cleaning tasks. Upon completion of the cleaning
                        tasks, the furniture will be arranged in the original pattern.</p>

                    <p id='subtit'>Clean Drinking Fountains</p>
                    <p id='conttext'>Remove all obvious soil, streaks, smudges, etc. from drinking fountains, and the entire cabinet; then disinfect
                        all polished metal surfaces including the orifices and drain. Stainless steel sections will be polished with an
                        appropriate cleaner. After cleaning, the entire drinking fountain will be free from streaks, stains, spots,
                        smudges, scale, and other obvious removable soil.</p>

                    <p id='subtit'>Vacuum Carpets Completely</p>
                    <p id='conttext'>Prior to vacuuming, all surface litter such as paper, gum, rubber bands, staples, paper clips, etc. will be picked
                        up. Furniture and trash receptacles will be moved as necessary to vacuum underneath. After vacuuming the
                        entire floor, it will be free of all visible litter, soil, dust, and embedded grit, including corners, under furniture,
                        and next to the baseboards.</p>

                    <p id='subtit'>Spot Vacuum Traffic Areas</p>
                    <p id='conttext'>Prior to vacuuming, all surface litter will be removed, including paper, gum, rubber bands, staples, paper clips,
                        and the entrances and the heavily traveled paths within the room will be vacuumed. After vacuuming, the
                        carpet will be free of all visible litter, dust, and soil.</p>

                    <p id='subtit'> Wet-Mop No Carpeted Floors</p>
                    <p id='conttext'>Prior to being mopped, the floor surfaces will be swept using a broom or dust mop. Remove all soil and nonpermanent stains from the entire area to be mopped. In restrooms, locker rooms and showers, a germicidal
                        detergent will be used. All accessible areas will be mopped. Chairs, trash receptacles, etc. will be moved to
                        mop underneath and not placed back on the floor until it is dry. After mopping, the floors will have a uniform
                        appearance with no streaks, film, swirl marks, detergent residues, mop strings or other evidence of soil. There
                        will be no splash marks or mop streaks left on furniture, walls, baseboards, or other surfaces. Any such
                        splashing or marking will be removed immediately. Water must be applied sparingly and not be allowed to
                        stand on floor surfaces.</p>

                    <p id='subtit'>Spray Buff Halls & Traffic Wear Areas</p>
                    <p id='conttext'>Prior to being spray buffed, the tile floor area will be swept, or dust mopped and completely damp-mopped. A
                        single disc floor machine, spray buffing pad, and spray bottle with spray buff solution shall be used to restore a
                        uniform floss and protective floor finish. The floor finish will be the same type as that already on the floor. All
                        areas showing wear and scuffing will be spray buffed. The floor will be dust mopped after being spray buffed.
                        After spray buffing, the area will have a uniform glossy appearance, free of scuffmarks, heel marks, and
                        scratches. All spray buff solution will be removed from baseboards, furniture, trash receptacles, and all other
                        surfaces.</p>

                    <p id='subtit'>Spray Buff Complete Tile Floor</p>
                    <p id='conttext'>The same preparatory steps are to be taken as in Paragraph 12 with the additional step, if necessary, that chairs,
                        trash receptacles, and other movable items will be moved where necessary to spray buff underneath. Whenever
                        furniture is rearranged and unsightly wash buildup and stains are on the floor, that section shall be scrubbed to
                        remove the buildup and then recoated. The floor will be dust mopped after being spray buffed. After spray
                        buffing, the entire floor will have a uniform, glossy appearance, free of scuffmarks, heel marks, and scratches. All
                        spray buff solution and floor finish will be removed from baseboard, furniture, and trash receptacles.</p>

                    <p id='subtit'>Clean Entrance Mats</p>
                    <p id='conttext'>Entrance mats located in either the exterior or the interior of entrances will be cleaned. If vacuuming
                        does not remove the affected soil, the mats will be taken outside and swept with a stiff broom until all
                        visible soil has been removed. Entrance mats, including recess mats, will be lifted to remove soil
                        and moisture underneath, and will then be returned to the normal location after cleaning. No entrance
                        mat will be placed upon a damp or wet floor surface.
                    </p>
                    <p id='subtit'>Spot Removal —Carpets</p>
                    <p id='conttext'>Carpets will be checked daily for stains. All stain areas will be treated with spot cleaning
                        solutions, following the direction of the manufacturer for the specific stain involved. Spot
                        cleaning will continue until as much of the stain as possible is removed. After cleaning to remove
                        spots, the carpet should be free from visible spots and stains, and the nap should be brushed all in
                        one direction.</p>

                    <p id='subtit'>Building Security - Locking and unlocking</p>
                    <p id='conttext'>Building locking and unlocking schedule will be strictly followed. Once a building is locked, the
                        custodian will not open doors to allow a person to enter. Only people with keys and authorization
                        are allowed in the County buildings after hours. Security will be maintained in the individual areas
                        within the building during the whole work shift. Doors to unattended, lockable rooms will be kept
                        locked during the shift except while being serviced. Any problem dealing with unauthorized people,
                        theft and vandalism will be immediately reported.</p>

                    <p id='subtit'> Clean Counters and Sinks</p>
                    <p id='conttext'>Remove any nonpermanent stains, spots, spills from all counters and sinks using a sponge or cloth
                        dampened in mild detergent solution. In restrooms and lockers use a germicidal detergent
                        solution. After washing, the counter and sinks will have a uniform appearance, free from streaks,
                        spots, smudges, or lint, with complete removal of soil from the surface.</p>
                </div>
                <div>
                    <p id='subtit'>Spot Clean Internal Building Surfaces/Internal Glass</p>
                    <p id='conttext'>Remove smudges, fingerprints, marks, streaks, and soil from washable surfaces including stainless
                        steel, around light switches, doors, door handles and casings, telephone stations, interior glass,
                        bulletin board and display cases, walls, entry areas, laminated plastic surfaces, spot clean venetian
                        blinds, kick and push plates, and other handled surfaces. Germicidal detergents will be used for
                        spot cleaning in restrooms and locker rooms. After cleaning, the surface will present a uniform
                        appearance free of all smudges, fingerprints, stains, streaks, lint, and other irregularities.</p>

                    <p id='subtit'>Clean Entrance Glass - Both Sides</p>
                    <p id='conttext'>Completely clean both sides of entrance glass and the window immediately adjacent to the
                        entrance doors as necessary. Spot clean both sides of the entrance doors. After cleaning, the
                        surface will present a uniform appearance free of all smudges, fingerprints, stains, streaks, lint, and
                        other irregularities.</p>

                    <p id='subtit'> Wash Blinds</p>
                    <p id='conttext'>In the cleaning process, the blind is not expected to be removed. The hardware will not be
                        damaged, and the blind will operate properly after cleaning. In cleaning blinds, all
                        surfaces of the slats are to be completely wiped on both sides using a detergent solution with a
                        damp cloth/sponge to remove all visible soil, smudges, streaks, and nonpermanent stains. Blinds
                        must be completely dried after cleaning. After cleaning, the blind will have all visible soil,
                        smudges, nonpermanent stains, streaks, and dust, removed and will present a uniform clean
                        appearance.</p>

                    <p id='subtit'> Re-wax Tile Floors</p>
                    <p id='conttext'>In preparation, the floor will be dust mopped to remove all loose soil. Power scrub floor to remove
                        spills, nonpermanent stains, and soil. If there are black marks, marks from chair glides, or
                        embedded soil in the finish, the floor will be partially stripped by machine scrubbing. Partial
                        stripping is the removal of only 1 or 2 layers (coats) of floor finish. Floors with excessive wax
                        build-up shall be stripped before re-waxing and refinished with the required number of coats. No stripping or
                        detergent solution will remain on the baseboards, doors, or other non-floor surfaces. Each coat of wax will be
                        thoroughly buffed before next coat is applied.</p>

                    <p id='subtit'> Wash All Hard Surfaces of Furniture</p>
                    <p id='conttext'>Using a cloth or sponge soaked in a germicidal detergent, completely clean all flat surfaces, table
                        and chair legs or supports, benches, stools, and exercise equipment pads removing spills, spots,
                        and nonpermanent stains. After cleaning, there shall be a uniform appearance free from spots,
                        spills, lint, dust, film, and streaks, with no remaining light and dark areas. Gum shall be removed
                        from all surfaces.</p>

                    <p id='subtit'>Clean Mirrors</p>
                    <p id='conttext'>Remove soil, streaks, smudges, and film from the surface of mirrors with a damp cloth or sponge.
                        Polish dry with a paper towel. After cleaning, surface will have no visible streaks, smudges, lint,
                        film, or soil, and present a uniform clean appearance. The frame of the mirror and shelves and
                        other adjacent areas also will be cleaned and free of dust.</p>

                    <p id='subtit'>Refill Paper Products Dispensers</p>
                    <p id='conttext'>Resupply all paper towel dispensers to the proper level. The dispensers and adjacent surfaces will be wiped
                        with germicidal detergent to remove handprints and smudges after filling. The device will be checked for proper
                        operation after filling and inoperable devices will be reported daily.</p>

                    <p id='subtit'>Refill Soap Dispensers</p>
                    <p id='conttext'>Soap dispensers will be filled with the proper amount and type of soap as necessary. The dispensers and
                        adjacent surfaces will be wiped with a germicidal detergent to remove handprints and smudges after filling. The
                        device will be checked after filling for proper operation. Remove the semi-hardened soap from spout of liquid
                        soap dispensers daily. Inoperable devices will be reported as noticed.</p>

                    <p id='subtit'>Clean and Disinfect Sinks</p>
                    <p id='conttext'>Completely clean and disinfect all exposed surfaces of the sink. A nonabrasive cleaner will be
                        used on the exposed hardware. The cleaning includes the drying and polishing of all exposed
                        hardware. After cleaning, the fixture will represent a clean, bright shiny appearance and will be
                        free of all visible soil, streaks, spots, oily smudges, or residue-cleaning agents. All metal
                        hardware, such as faucet valves, drains and faucets, will be free of streaks, spots, and stains.
                        Inoperable or broken fixtures will be reported immediately. Different cloths, sponges, brushes and
                        scouring pads will be used to clean the sinks than the ones used for cleaning the toilets and urinals.</p>

                    <p id='subtit'>Clean and Disinfect Toilets and Urinals</p>
                    <p id='conttext'>Completely scrub clean and disinfect all exposed surfaces (inside and out) of the toilets and urinals,
                        paying particular attention to areas under the rim and water inlet orifices. A nonabrasive cleaner
                        will be used on the exposed hardware. The cleaning includes the drying and polishing of all
                        exposed hardware. All foreign material will be removed from the urinal drain trap and the
                        surfaces of the urinal drain not covered by water will be thoroughly scrubbed. A special set of
                        sponges, cloths, scouring pads, and brushes will be maintained and used only for cleaning the
                        urinals and toilets. After cleaning, the toilet seat must be completely dried and placed in an
                        upright position. All fixtures will present a clean, bright shiny appearance and will be free of all
                        streaks, spots, stains, rings, foreign material, and residue-cleaning agents, including the metal
                        hardware. Stopped up toilets will be plunged to free it of the obstruction. Only if the obstruction cannot be
                        dislodged completely will it be reported.</p>

                    <p id='subtit'>Descale Toilets and Urinals</p>
                    <p id='conttext'>Remove scale, scum mineral deposits, and rust stains from the interior of toilet bowls and urinals.
                        After cleaning, toilets and urinals will be free from rings, mineral deposits, and rust stain and will
                        have a uniform, bright shining appearance.</p>

                    <p id='subtit'>Clean Floor Drains</p>
                    <p id='conttext'>Remove grate if possible. Clean to remove all built-up deposits, embedded hairs, and soil from the
                        grate and neck of the drain. Replace the grate. After wet mopping the floor, empty the remaining
                        germicidal detergent down the floor drain. This will both disinfect the drain and will seal the
                        trap. After cleaning, the drain and grate will be free of odors and built-up deposits.
                    </p>
                    <p id='subtit'>Mop and Disinfect Floor</p>
                    <p id='conttext'>Prior to mopping the floor surface will have been swept with a broom to remove all loose dirt and
                        soil. Mop the floor with a germicidal detergent solution and rinse with clear water. After
                        mopping, the floor will have a uniform appearance free of spots, spills, stains, dirt, oily film, mop
                        strings, standing water, or other irregularities. Dispose of the remaining detergent solution by
                        pouring it down the floor drain. Including but not limited to restrooms and examination rooms.
                    </p>
                    <p id='subtit'>Damp Wipe Hardware</p>
                    <p id='conttext'>After wiping fixtures with a nonabrasive germicidal detergent, dry and polish with dry cloth. After
                        cleaning, fixtures will be dry and polished with a uniform appearance from spots, stains and soil.</p>

                    <p id='subtit'>Wash Exterior Windows</p>
                    <p id='conttext'>Wash glass surfaces using mild detergent, or a slightly acidic cleaning solution applied either
                        with a soft, clean, grit free cloth or soft brush. Immediately rinse with clean water. Promptly dry
                        using clean squeegee. Unless otherwise arranged, exterior window surfaces shall be washed from
                        the outside of the building.</p>
                    <p id='subtit'>Stripping and Refinishing</p>
                    <p id='conttext'>(A) Stripping — This will be as a need basis, yearly, Is the complete removal, without damage to
                        the floor surface, of all finish and/or sealer from all visible floor surfaces and from those floor
                        surfaces which can be exposed by the removal of nonfixed furnishings. Stripping also includes the
                        complete removal of all marks, scuffs, and stains, except in cases in which there is damage to the
                        floor surfaces. Where stripping chemicals are used, the areas will be rinsed to completely remove
                        any traces of the solution. The PH of the floor surface will be brought to neutral (PH of 7) after
                        stripping by rinsing with a mild acid base detergent. Stripping solution will be wiped off
                        immediately from baseboards and other nonfixed items in the area. During the stripping process,
                        care will be taken to avoid flooding of the floor with either the stripping solution or the rinse so as
                        to prevent damage to the tile and its bond to the sub floor.
                        <br />(B) Refinishing - This will be as a need basis. After a floor has been stripped, two coats of
                        sealer will be applied. Then at least two coats of metal interlock polymer floor finish will be
                        applied. Only every other coat will be applied all the way to the baseboard. The alternating coat
                        will be applied as to leave a 12-inch border around the area. The coats will be thin and evenly
                        applied. After the finish has dried, the reflectance will be uniform and no streaks, swirls, or other
                        irregularities will be visible, and no finish will be on the baseboard or other fixed equipment in the
                        area. Each coat of sealer or wax will be buffed thoroughly before next coat is applied.</p>

                    <p id='subtit'>Carpet Cleaning - Water Extraction Method</p>
                    <p>
                        Includes cleaning, vacuuming, operation of the water extraction equipment, and vacuuming the
                        carpet area. The loose litter will be picked up and the carpet area to be extracted will be
                        completely vacuumed. Spots and stains will be treated with the appropriate spotter for the
                        stains. All traffic areas will be sprayed with a pre-spotter solution. The water extraction
                        equipment and solution will be used and operated in accordance with the manufacturer's
                        instructions. After extraction, the carpet will be vacuumed again when the carpet is dry as to
                        give the carpet a uniform appearance. Any carpet extracted will be completely dry by morning of
                        the next workday. Furniture with metal glides or any other rustable metal surface must not come
                        in contact with damp carpets.</p>

                    <p id='subtit'> Routine Attention to Rooms</p>
                    <p id='conttext'>After performing custodial tasks in any area, attention must be paid to the following details:
                        Straighten blinds or draperies, close windows, turn down thermostats, turn off lights, close and lock doors.</p>

                    <p id='subtit'>Janitorial Area</p>
                    <p id='conttext'>Must always be kept clean and neat. Supplies are put in proper places as they arrive, discarding all boxes,
                        bottles, and papers.</p>

                    <p id='subtit'>Kitchenettes/Break rooms</p>
                    <p id='conttext'>A properly cleaned kitchenette must be free of all dust, dirt, soil and spills. Floors - Dust mop daily, spot mop
                        where needed, damp mop all floor areas once a week or when needed. Walls, door, light switches, heaters,
                        ledges - damp mop daily, removing all soil, fingerprints and dirt. Waste Containers - empty daily, line with
                        plastic liner.</p>
                </div>
            </div>
        </div>
    )
}

export default Services