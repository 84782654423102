import React from 'react'
import '../css/contact.css'

import tp from '../img/tt.jpg'
import cheader from '../img/cheader.png'

function Contact() {
    return (
        <div className='concontain'>
            <img id='cheader' src={cheader} alt='headerc' />
            <div id='hrct' />

            <div className='crow'>
                <div className='timgc'>
                    <img src={tp} id='timg' alt='Turnner Proffesional' />
                </div>
                <div className='ctext'>
                    <p id='subtc'>Greetings,</p>
                    <p>With much enthusiasm and sincerity, I would like to say thank you for taking the time to consider Turner's
                        Cleaning Service as an option for your janitorial needs. I hope you found after reviewing this portfolio and seeing my experiences
                        in assisting others with presenting a pristine and professional image, has long been a passion of mine. I learned early the value of comfort and relief one feels after having a freshly cleaned
                        and organized space. From my experience in the United States Navy, I have a strong foundation in three key
                        principals I have implemented into Turner's Cleaning Service: Respect, Professionalism, and Quality. Those
                        principals drive my business as well as my personal endeavors; therefore, that is my promise to you both
                        personally and professionally.</p>
                    <p id='subc'>Poncie L. Turner, Owner/Manager<br />
                        Turner's Cleaning Service, LLC</p>
                </div>
            </div>
            <div id='hrc' />
            <div className='back'>
                <div className='clientsContact'>
                    <p id='clientHead'>Listed below are prior and/or current clients of Turner's Cleaning Service and the scope of services rendered:</p>
                    <p><b>BREG, OPENWORKS —</b> FLOWOOD, MS</p>
                    <p id='subtcl'>Routine general cleaning and sanitization of 5-room office space with kitchenette and restroom; dusting of
                        furniture and surfaces, mopping, vacuuming; interior clean of windows; trash removal; clean and sanitize
                        restrooms.
                    </p>
                    <p><b>UNITED PENTECOSTAL HEAD QUARTERS -</b> CLINTON MS</p>
                    <p id='subtcl'>Seasonal deep cleaning and sanitization of 8-building facility including dormitories, church, and event center;
                        clean and shampoo carpet; strip, wax, and buff floors; wax hard surfaces; clean and sanitize restrooms; exterior
                        and interior glass cleaning; entryway clearing and cleaning; sanitize drinking fountains; trash removal; routine
                        cleaning and maintenance.
                    </p>
                    <p><b>UNITED PENTECOSTAL CAMPGROUND — </b>RAYMOND, MS</p>
                    <p id='subtcl'>Routine cleaning and sanitization of moderately-sized church facility; occasional shampoo of carpet; buff and wax
                        floors and hard surfaces as needed; trash removal; clean and sanitize restrooms; dust, vacuum, and mop; clean
                        and shine furniture and building furnishings; exterior and interior glass cleaning.</p>

                    <p><b>CORNER BAKERY — </b>MADISON, MS</p>
                    <p id='subtcl'>Cleaning of exterior restaurant building. Cleaned and cleared away debris, dust, and trash from awning.</p>

                    <p><b>CORNER BAKERY - </b>FLOWOOD, MS</p>
                    <p id='subtcl'>Cleaning of exterior restaurant building. Cleaned and cleared away debris, dust, and trash from awning</p>

                    <p><b>CHURCH'S CHICKEN — </b>JACKSON, MS</p>
                    <p id='subtcl'>After-construction extensive cleaning; cleaned and shined floors; cleaned exterior and interior windows; extensive
                        cleaning of kitchen equipment and floors & counters; trash removal; dusting; mopping.</p>

                    <p><b>CARE CAR CLINIC — </b>JACKSON, MS</p>
                    <p id='subtcl'>Periodic floor treatments — tile surface; periodic shampoo treatments to carpet in building.</p>

                    <p><b>BONNIE RAY'S COFFEE SHOP — </b>BYRAM, MS</p>
                    <p id='subtcl'>Stripped and waxed of floors; clean glass windows — interior and exterior.</p>

                    <p><b>MARKET PLACE FESTIVAL — </b>FLOWOOD, MS</p>
                    <p id='subtcl'>Replaced and clean interior light fixtures in facility.</p>

                    <p><b>FITNESS PLEX — </b>FLORENCE, MS</p>
                    <p id='subtcl'>Stripped and waxed floors; periodic up-keep with buffing/shining of floors. Shampoo carpet routinely.</p>

                    <p><b>TACO BELL — </b>RICHLAND, MS</p>
                    <p id='subtcl'>After construction clean up — Dust/Sheetrock clearing and removal, buff floors; interior and exterior glass cleaning;
                        kitchen equipment deep clean; dusting, mopping, and vacuuming; clean and sanitize all restrooms.</p>







                </div>
            </div>
        </div>
    )
}

export default Contact