import React from 'react'
import C from '../components/chontact'
import '../css/quotes.css'

export default function Quotes() {
  return (
    <div className='qcontain'>
    <C />
    </div>
  )
}
